import { INotification } from 'models/notifications';

//*Icons
import shield from 'assets/icons/shield.svg';
import shieldError from 'assets/icons/shieldError.svg';

import groupSheetIcon from 'assets/icons/groupSheets.svg';
import closeSmall from 'assets/icons/close-small.svg';

import format from 'date-fns/format';
import { es } from 'date-fns/locale';

import styles from './QueryNotification.module.scss';
import { useMemo, useCallback } from 'react';
import { useDownloadAlertsStore } from 'hooks/useDownloadAlertsStore/index';

import { useNotifications } from 'components/collections/Notifications/context';
import { NotificationTypes } from 'common';

const NOTIFICATION_COUNTER_TYPE = {
  [NotificationTypes.POLICY_REPORT]: 'Registros',
  [NotificationTypes.RENOVATION_REPORT]: 'Pólizas',
  [NotificationTypes.CERTIFICATES_REPORT]: 'Certificados',
  [NotificationTypes.CERTIFICATES_RENOVATION_REPORT]: 'Certificados',
  [NotificationTypes.CLAIMS_REPORT]: 'Registros',
  [NotificationTypes.COLLECTIVE_PDF_DOWNLOAD_REPORT]: 'Documentos'
};

const NOTIFICATION_TITLE = {
  [NotificationTypes.POLICY_REPORT]: 'Descarga listado pólizas',
  [NotificationTypes.RENOVATION_REPORT]: 'Descarga listado renovaciones',
  [NotificationTypes.CERTIFICATES_REPORT]: 'Descarga listado certificados',
  [NotificationTypes.CERTIFICATES_RENOVATION_REPORT]: 'Descarga listado certificados',
  [NotificationTypes.CLAIMS_REPORT]: 'Descarga listado siniestros'
};

const NOTIFICATION_EXCEL_TITLE = {
  [NotificationTypes.POLICY_REPORT]: (policyNumber, dateToDocument) =>
    `Listado pólizas_${dateToDocument}`,
  [NotificationTypes.RENOVATION_REPORT]: (policyNumber, dateToDocument) =>
    `Renovaciones_${dateToDocument}`,
  [NotificationTypes.CLAIMS_REPORT]: (policyNumber, dateToDocument) =>
    `Listado siniestros_${dateToDocument}`,
  [NotificationTypes.CERTIFICATES_REPORT]: (policyNumber, dateToDocument) =>
    `Póliza ${policyNumber}_Listado certificados_${dateToDocument}`,
  [NotificationTypes.CERTIFICATES_RENOVATION_REPORT]: (policyNumber, dateToDocument) =>
    `Póliza ${policyNumber}_Listado certificados_${dateToDocument}`,
  [NotificationTypes.COLLECTIVE_PDF_DOWNLOAD_REPORT]: (policyNumber) => `Póliza ${policyNumber}`
};

interface QueryNotificationProps {
  notification: INotification;
}

export const QueryNotification = ({ notification }: QueryNotificationProps) => {
  const { startDownloadExcel, startDownloadZip } = useDownloadAlertsStore();
  const { deleteNotification } = useNotifications();

  const formateDate = () => {
    if (notification?.createDate) {
      const date = new Date(notification?.createDate);
      const formateDate = format(date, 'dd/MMM/yyyy - HH:mm aaaa', { locale: es });
      return formateDate.replace(formateDate[3], formateDate[3].toUpperCase());
    } else {
      return '-';
    }
  };

  const dateToDocument = useCallback(() => {
    if (notification?.createDate) {
      const date = new Date(notification?.createDate);
      const formateDate = format(date, 'dd/MMM/yyyy', { locale: es });
      const dateArray = formateDate
        .replace(formateDate[3], formateDate[3].toUpperCase())
        .split('/');
      return `${dateArray[0]}${dateArray[1]}${dateArray[2]}`;
    } else {
      return '-';
    }
  }, [notification]);

  const totalData = notification?.content?.totalData;

  const policyNumber =
    notification?.type === NotificationTypes.POLICY_REPORT
      ? notification?.content?.payload?.policy_number
      : notification?.content?.payload?.policy;

  const nameDocument = useMemo(() => {
    return NOTIFICATION_EXCEL_TITLE[notification?.type]?.(policyNumber, dateToDocument()) || '';
  }, [notification?.type, dateToDocument, policyNumber]);

  return (
    <div
      className={styles.notification}
      style={{
        backgroundColor: !notification?.content.file || notification?.downloadAt ? '' : '#F2FAF5'
      }}
    >
      <img className={styles.notification__icon} src={groupSheetIcon} alt="groupSheetIcon" />

      <div className={styles.notification__content}>
        <span className={styles.title}>{NOTIFICATION_TITLE[notification?.type]}</span>
        <span className={styles.date}>{formateDate()}</span>
        <div
          className={styles.process}
          style={{
            borderLeft: notification.content.file ? '2px solid #03ac63' : '2px solid #E92243'
          }}
        >
          <div
            className={
              notification?.content.file ? styles.process__icon : styles.process__icon_error
            }
          >
            <img src={notification?.content.file ? shield : shieldError} alt="shield" />
          </div>
          <span
            className={styles.process__action}
            onClick={() => {
              const file = notification?.content?.file;
              if (file) {
                const ext = file.split('.').pop();
                startDownloadExcel(notification?.id, `${nameDocument}.${ext}`);
              }
            }}
          >
            {notification?.content?.file ? 'Descargar excel' : 'Error en descarga'}
          </span>
        </div>
      </div>

      <div className={styles.notification__quantity}>
        <span>
          {totalData ? `${totalData} ${NOTIFICATION_COUNTER_TYPE[notification?.type]}` : '-'}
        </span>
      </div>

      <div>
        <img
          className={styles.notification__icon_close}
          src={closeSmall}
          alt="closeSmall"
          onClick={() => {
            deleteNotification(notification?.id);
          }}
        />
      </div>
    </div>
  );
};
