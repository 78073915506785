//icons
import shield from 'assets/icons/shield.svg';
import shieldError from 'assets/icons/shieldError.svg';
import groupSheetIcon from 'assets/icons/groupSheets.svg';
import closeSmall from 'assets/icons/close-small.svg';
//react
import { useCallback, useMemo } from 'react';
//components
import { useDownloadAlertsStore } from 'hooks/useDownloadAlertsStore';
import useNotifications from 'hooks/useNotifications';
import format from 'date-fns/format';
import { es } from 'date-fns/locale';
import { NotificationTypes } from 'common';
//styles
import styles from './NotificationDownloadPDF.module.scss';

export const NotificationDownloadPDF = ({ notification }) => {
  const { startDownloadExcel, startDownloadZip } = useDownloadAlertsStore();
  const { deleteNotification } = useNotifications();

  const formateDate = () => {
    if (notification?.createDate) {
      const date = new Date(notification?.createDate);
      const formateDate = format(date, 'dd/MMM/yyyy - HH:mm aaaa', { locale: es });
      return formateDate.replace(formateDate[3], formateDate[3].toUpperCase());
    } else {
      return '-';
    }
  };

  const certificatesError = useMemo(
    () => notification.content.totalData?.totalErrors,
    [notification.content.totalData?.totalErrors]
  );

  return (
    <div
      className={styles.notification}
      style={{
        backgroundColor: !notification?.content.file || notification?.downloadAt ? '' : '#F2FAF5'
      }}
    >
      <img className={styles.notification__icon} src={groupSheetIcon} alt="groupSheetIcon" />

      <div className={styles.notification__content}>
        <span className={styles.title}>
          {`Descarga PDF Póliza ${notification.content.payload.policyNumber}`}
        </span>
        <span className={styles.date}>{formateDate()}</span>

        <div className={styles.document_download}>
          <div
            className={styles.process}
            style={{
              borderLeft: notification.content.file ? '2px solid #03ac63' : '2px solid #E92243'
            }}
          >
            <div
              className={
                notification?.content.file ? styles.process__icon : styles.process__icon_error
              }
            >
              <img src={notification?.content.file ? shield : shieldError} alt="shield" />
            </div>
            <div className={styles.document_content}>
              <span className={styles.document_title}>{`${
                notification.content.totalData?.totalSuccess - 1
              } Certificados vigentes`}</span>
              <span
                className={styles.document_action}
                onClick={() => {
                  const file = notification?.content?.file;
                  if (file) {
                    startDownloadZip(
                      notification?.id,
                      `Póliza ${notification.content.payload.policyNumber}.zip`,
                      { type: 'zip' }
                    );
                  }
                }}
              >
                {notification?.content?.file ? 'Descargar PDFs' : ''}
              </span>
            </div>
          </div>
          <div
            className={styles.process}
            style={{
              borderLeft: '2px solid #E92243'
            }}
          >
            <div className={styles.process__icon_error}>
              <img src={notification?.content.file ? shieldError : shield} alt="shield" />
            </div>
            <div className={styles.document_content} style={{ width: '140px' }}>
              <span className={styles.document_title}>{`${certificatesError} Certificado${
                certificatesError != 1 ? 's' : ''
              } con error`}</span>
              <span
                className={styles.document_action}
                style={{ color: '#919191' }}
                onClick={() => {
                  const file = notification?.content?.file;
                  if (file) {
                    startDownloadExcel(
                      notification?.id,
                      `Reporte descarga póliza completa ${notification.content.payload.policyNumber}.xlsx`,
                      { type: 'excel' }
                    );
                  }
                }}
              >
                {notification?.content?.file ? 'Descargar reporte errores' : ''}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <img
          className={styles.notification__icon_close}
          src={closeSmall}
          alt="closeSmall"
          onClick={() => {
            deleteNotification(notification?.id);
          }}
        />
      </div>
    </div>
  );
};
