import clsx from 'clsx';
import PropTypes from 'prop-types';

import Header from './components/Header';
import LibertyModalBase from './components/LibertyModalBase';

import styles from './LibertyModal.module.scss';

const LibertyModal = ({
  open,
  image,
  imageClassName = null,
  title, 
  description,
  confirm,
  containerClassname: containerClassName,
  actionButtons,
  children,
  onClose,
  buttonStyle = null,
  noImageClass = false,
  closeIconClass = null,
  header = true
}) => {
  return (
    <LibertyModalBase
      {...{
        open,
        containerClassName,
        children,
        onClose,
        buttonStyle,
        closeIconClass
      }}
    >
      {header && (
        <Header
          onClose={onClose}
          style={buttonStyle}
          className={closeIconClass}
          containerClassName={containerClassName}
        />
      )}
      <div className={clsx(styles.content, containerClassName)}>
        {image && <div className={clsx(!noImageClass && styles.img, imageClassName)}>{image}</div>}
        <div className={styles.info}>
          <span className={styles.title}>{title}</span>
          {description && <span className={styles.description}>{description}</span>}
        </div>
        {children && <div className={styles.children}>{children}</div>}
        {confirm && <div className={styles.confirm}>{confirm}</div>}
        <div className={styles.actions}>{actionButtons}</div>
      </div>
    </LibertyModalBase>
  );
};

LibertyModal.propTypes = {
  open: PropTypes.bool,
  image: PropTypes.node,
  title: PropTypes.node,
  description: PropTypes.node,
  confirm: PropTypes.node,
  containerClassname: PropTypes.string,
  actionButtons: PropTypes.node,
  children: PropTypes.node,
  onClose: PropTypes.func
};

export default LibertyModal;
